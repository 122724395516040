/* #region Box Cookie policy */
#privacypolicy---preferences-center, .privacypolicy---pc-dialog .cc-cp-body-tabs-item-link {
  font-family: "Inter", sans-serif;
}

.privacypolicy---palette-light.privacypolicy---nb {
  background-color: var(--dark) !important;
}

.privacypolicy---palette-light .cc-nb-okagree {
  color: white !important;
  background-color: var(--main) !important;
  border: 1px solid var(--main) !important;
  border-radius: var(--radius);
  display: block;
  width: 55% !important;
  margin-left: 45% !important;
}

.privacypolicy---palette-light .cc-nb-changep {
  color: var(--black) !important;
  background-color: var(--light-gray) !important;
  border: 1px solid var(--light-gray) !important;
  border-radius: var(--radius);
  display: block;
  width: 55% !important;
  margin-left: 45% !important;
}

.privacypolicy---palette-light .cc-nb-reject {
  color: var(--black) !important;
  background-color: var(--light-gray) !important;
  border: 1px solid var(--light-gray) !important;
  border-radius: var(--radius);
  display: block;
  width: 55% !important;
  margin-left: 45% !important;
}

@media (max-width: 768px) {
  .privacypolicy---palette-light .cc-nb-okagree,
  .privacypolicy---palette-light .cc-nb-changep,
  .privacypolicy---palette-light .cc-nb-reject {
    width: 100% !important;
    margin-left: 0 !important;
  }
  /*ceckbox mobile*/
  input[type=checkbox] {
    -webkit-transform: scale(1,1) !important;
    padding: 0 !important;
    margin-left: 0px !important;
  }
}

.privacypolicy---palette-light .cc-nb-text {
  color: #fff !important;
}

.cc-nb-text a {
  color: #fff !important;
}

.privacypolicy---palette-light .cc-pc-head-lang select:focus {
  box-shadow: none !important;
}

.privacypolicy---palette-light .cc-cp-foot-save {
  margin-right: 10px !important;
  background: var(--main) ;
  padding: 10px 20px !important;
  border-radius: var(--radius);
  color: white;
}

div#privacypolicy---nb:not(.privacypolicy---is-hidden) {
  padding: 50px 16% 50px 15%;
  display: flex;
  justify-content: center
}

.privacypolicy---palette-light .cc-cp-body-tabs-item-link:focus {
  box-shadow: none !important;
}

.cc-pc-head-title-text,
.cc-pc-head-lang-select{
  display: none !important;
}


/*end cookie*/
