/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root{
  --dark: #222531;
  --main: #3861FB;
  --light: #F0F6FF;
  --light-gray: #F4F6F8;
  --light-blue: #EAEFFF;
  --gray: #8D96A7;
  --red: #FA3737;
  --green: #06DB2D;
  --blur: #5679FC;
  --black: #212529;
  --radius: 5px;
  --transitions: 0.5s;
  /*index crisis*/
  --crisis-great: #C24747;
  --crisis-great-opacity: rgba(194, 71, 71, 0.2);
  --crisis-major: #DD4949;
  --crisis-major-opacity: rgba(221, 73, 73, 0.2);
  --crisis-strong: #FB6B4D;
  --crisis-strong-opacity: rgba(251, 107, 77, 0.2);
  --crisis-moderate: #FB984D;
  --crisis-moderate-opacity: rgba(251, 152, 77, 0.2);
  --crisis-small: #FBAA4D;
  --crisis-small-opacity: rgba(251, 170, 77, 0.2);
  --crisis-minor: #FBCD4D;
  --crisis-minor-opacity: rgba(251, 205, 77, 0.2);
  /* overwrite */
  --bs-border-radius: var(--radius);
  --bs-border-color: var(--light-gray);
}

*{
  font-family: "Inter", sans-serif;
}

html{
  /*scrollbar-gutter: stable;*/
  overflow-y: scroll;
}

body:has(.page-gray){
  background-color: var(--light-gray);
}

body:has(.modal-is-open){
  overflow-y: hidden;
}

@media screen and (max-width: 576px) {
  body:has(.container-page-admin) {
    padding: 5px;
  }
}

.color-black{
  color: var(--black);
}

.color-dark{
  color: var(--dark);
}

.bg-dark{
  background-color: var(--dark) !important;
}

.color-main{
  color: var(--main);
}

.bg-main{
  background-color: var(--main);
}

.bg-light-gray{
  background-color: var(--light-gray);
}

.bg-gray{
  background-color: var(--gray);
}

.bg-light-blue{
  background-color: var(--light-blue);
}

.color-gray{
  color: var(--gray);
}

a{
  text-decoration: none;
  font-weight: bold;
}

a:hover{
  text-decoration: underline;
}

hr{
  border-top-color: white;
  border-top-width: 1px;
}

.hr-gray{
  border-top-color: var(--light-gray);
  opacity: 1;
}

.container-page-admin{
  min-height: calc(100vh - calc(122px + 4rem) - calc(65px + 3rem));
}

.container-page-public{
  min-height: calc(100vh - 88px - 313px);
  margin-top: 88px;
}

@media screen and (max-width: 490px){
  .container-page-public{
    margin-top: 100px;
  }

}

.error{
  font-size: small;
  color: var(--red);
}

.border-radius{
  border-radius: var(--radius);
}

.border-end-radius{
  border-bottom-right-radius: var(--radius) !important;
  border-top-right-radius: var(--radius) !important;
}

.border-right{
  border-right: 1px solid var(--light-gray);
}

.box-shadow{
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.05)
}

.pointer{
  cursor: pointer;
}

.container.range-plugin .calendar>.days-grid>.day.end, .container.range-plugin .calendar>.days-grid>.day.start{
  background-color: var(--main);
}
.calendar>.days-grid>.day.today{
  color: var(--main);
  font-weight: bold;
}

.highcharts-button-box{
  fill: var(--light-gray);
  height: 36px;
  width: 36px;
}

.exportHidden .highcharts-contextbutton{
  display: none !important;
}

.tooltip-inner{
  text-align: left !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: #ccccd0;
  border-radius: var(--radius);
}
::-webkit-scrollbar-track {
  background: var(--light-gray);
  border-radius: 0;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.grecaptcha-badge{
  display: none !important;
}
